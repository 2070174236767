<template>
  <auth>
    <a-row
      type="flex"
      justify="center"
      align="middle"
      style="height :100vh ;"
      class="ant-row-flex bg"
    >
      <a-col :xs="22" :sm="14" :md="8">
        <a-alert
          v-if="errors"
          type="error"
          message="Email ou mot de passe erroné"
          style="margin-bottom: 30px;"
          banner
        />
        <a-form
          @submit="handleSubmit"
          :form="form"
          style="background-color: #ffffffa1;padding: 5%"
        >
          <h1 style="text-align : center">Réinitialiser mot de passe</h1>
          <a-form-item v-bind="formItemLayout">
            <a-input
              placeholder="Email"
              v-decorator="[
                'email',
                {
                  rules: [
                    { required: true, message: 'Email est obligatoire!' },
                    {
                      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                      message: 'Le format de l\'email n\'est pas valide ',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="mail"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <a-input-password
              placeholder="Mot de passe"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Mot de passe est obligatoire!',
                    },
                    {
                      pattern: /(?=.*\d.*)(?=.*[a-z].*).{8,20}/,
                      message:
                        'Le mot de passe doit contenir au moins 8 caractères !',
                    },

                    { validator: this.validateToNextPassword },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input-password>
          </a-form-item>
          <a-form-item v-bind="formItemLayout">
            <a-input-password
              type="password"
              @blur="handleConfirmBlur"
              placeholder="Confirmer mot de passe"
              v-decorator="[
                'password_confirmation',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Confirmation Mot de passe est obligatoire!',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input-password>
          </a-form-item>

          <a-form-item v-bind="tailFormItemLayout">
            <a-spin :spinning="spinning">
              <a-button
                type="primary"
                class="regiter-form-button"
                htmlType="submit"
                >Réinitialiser
              </a-button>
            </a-spin>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </auth>
</template>

<script>
import AInputPassword from "ant-design-vue/es/input/Password";
import {mapActions} from "vuex";
import Auth from "@/components/common/AuthParent";

const tailFormItemLayout = {
    wrapperCol: {
        xs: {span: 22},
        sm: {span: 22},
        md: {span: 22},
    },
};
const formItemLayout = {
    labelCol: {
        xs: {span: 4},
        sm: {span: 6},
    },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 22 },
    md: { span: 22 },
  },
};
const LOGIN_URL = "adherent_login";

export default {
  name: "ResetPasswordPage",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  components: {
    AInputPassword,
    Auth
  },
  created() {
    this.token = this.$route.params.token;
  },
  data() {
    return {
      confirmDirty: false,
      errors: false,
      spinning: false,
      token: null,
      formItemLayout,
      tailFormItemLayout,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          let token = this.$route.params.token;
          this.reset({ ...values, token: token })
            .then(() => {
              this.$_showSuccessMessage(
                "Votre mot de passe a été réinitialisé avec succès",
                10
              );
              this.$router.push({ name: LOGIN_URL });
            })
            .catch((err) => {
                this.spinning = false;
                if (err.response.status === this.HTTP_NOT_FOUND) {
                    this.$_throwAnError(
                        "Lien de réinitialisation est invalide !!",
                        10
                    );
                    return;
                }
                this.$_throwAnError("Un erreur est survenue !!", 10);
            });
        }
      });
    },
    displayErrors(state) {
      this.errors = state;
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("Les deux mots de passe ne sont pas identiques !");
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["password_confirmation"], { force: true });
      }
      callback();
    },
    ...mapActions({ reset: "resetPassword" }),
  },
};
</script>

<style scoped>
.bg {
 
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.regiter-form-button {
  width: 100%;
}
</style>
